import React from "react";
import Footer from "../../../components/sections/Footer";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";

import JotformEmbed from "react-jotform-embed";
import TitleDiv from "../../../components/controls/TitleDiv";

const path = ["Employment Application Form"];

const EmploymentApplicationPage = () => {
  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title="NWP Employment Application" />
      <Header />
      <TitleDiv
        parentText="Jobs"
        title="Employment Application Form"
        path={path}
      />
      <div className="w-full">
        <JotformEmbed src="https://form.jotform.com/221628698583471" />
      </div>
      <Footer />
    </div>
  );
};

export default EmploymentApplicationPage;
